@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .custom-scrollbar::-webkit-scrollbar{
        width: 4px;

    }

    .custom-scrollbar::-webkit-scrollbar-track{
        background: transparent;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb{
        background: #a5b4fc;
        width: 5px;
        border-radius: 10px;
    }

    .custom-scrollbar-dark::-webkit-scrollbar-thumb{
        background: #312e81;
    }
}